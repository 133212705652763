<template>
  <div style="background-image: linear-gradient(90deg, #1461e4, #736ff4);">
    <div class="float_right">
      <!-- <p><a class="link" @click="()=>{
            contentHtml = textInfo.rule
            contentHtmlModal = true
          }">活动规则</a></p> -->
      <!-- <p><router-link to="/service" class="link">联系客服</router-link></p> -->
      <p><a @click="service" class="link">联系客服</a></p>

      <p><router-link to="/refund" class="link" v-if="is_open_refund">极速退款</router-link></p>
    </div>

    <div class="recharge">
      <!-- <div class="header">
          <img src="https://ntyz.oss-cn-shanghai.aliyuncs.com/image/zdd/298f4171cafe8c74b99bcb971278f167.jpg" style="width: 100%;" alt="">
      </div> -->

      <div class="content">
        <div class="cont-top-img">

          <div style="background-image: url('https://toufang-images.sybaishan.top/images/quanyi/qyhf/zgyd.png'); background-size: 100% 100%; background-repeat: no-repeat;width: 88px; height: 24px;"></div>
          <div style="background-image: url('https://toufang-images.sybaishan.top/images/quanyi/qyhf/zgdx.png'); background-size: 100% 100%; background-repeat: no-repeat;width: 88px; height: 24px;"></div>

          <div style="background-image: url('https://toufang-images.sybaishan.top/images/quanyi/qyhf/zglt.png'); background-size: 100% 100%; background-repeat: no-repeat;width: 88px; height: 24px;"></div>

        </div>
        <div class="cont-title">三网联合话费预充值活动</div>
        <div style="display: flex; justify-content: center;">
          <div class="titlebig_left">充{{money.price}}元得</div>
          <div class="titlebig_rig">{{money.total_price}}元话费</div>
        </div>
        <div class="top_tips">支付成功即可领取<span style="color: rgb(221, 189, 49);">20元</span>,次月起每月可领取20元,共10个月</div>
        <div class="t2">
          <van-count-down millisecond :time="time">
            <template #default="timeData" >
              <span class="colon">支付剩余时间</span>
              <span class="block">{{ timeData.minutes }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.seconds }}</span>
              <span class="colon">:</span>
              <span class="block">{{moment(timeData.milliseconds).format("SS")}}</span>
            </template>
          </van-count-down>
        </div>

        <div class="input-cont">
          <div class="mobile">
            <van-field v-model="json.phone" type="tel" :border="false" ref="phone" placeholder="请输入充值手机号码"/>
         </div>
         <div class="input-cont-tit">支付方式</div>
         <div class="payList">
          <van-radio-group v-model="json.pay_way">
            <van-cell-group :border="false">
              <van-cell v-for="item in payList" :key="item.key">
                <template #icon><img :src="item.img" height="20"/></template>
                <template #title>
                  <van-row type="flex" align="center" :gutter="2">
                    <van-col>{{item.name}}</van-col>
                    <van-col><van-tag plain type="primary" color="#e8323c" text-color="#e8323c">首单随机立减，最高至免单</van-tag></van-col>
                  </van-row>
                </template>
                <template #right-icon>
                  <van-radio :name="item.payWay" checked-color="#07c160" icon-size="18px"></van-radio>
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
        <div class="paymoney">
          <p>实际支付付金额：<span class="font-color-blue">{{money.price}}元</span></p>
          <p>实际到账话费优惠券包：<span class="font-color-blue">{{money.total_price}}元</span></p>
        </div>
        <div class="ljzf-btn"  @click="onsubmit">

          <div class="input-save">省100元</div>
          <div class="input-pay">立即支付</div>
        </div>
        <div class="tips">
          <span><van-checkbox v-model="isread" icon-size="16px">提交即您已阅读并同意</van-checkbox></span>
          <a class="font-color-blue" @click="()=>{
            contentHtml = textInfo.privacy_policy
            contentHtmlModal = true
          }">《隐私协议》</a><a class="font-color-blue" @click="()=>{
            contentHtml = textInfo.user_agreement
            contentHtmlModal = true
          }">《用户协议》</a>

        </div>
        </div>
       </div>









        <div class="box mt20 " style="padding: 10px; margin: 10px 10px;">
        <div class="lm t-c">话费优惠券包说明</div>
        <div class="content mt15" v-html="detail.detail"></div>

        <div class="font-color-gray t-c mt15">
          <p>客服电话：{{textInfo.service_phone}}</p>
          <p>{{textInfo.company_name}}</p>
          <p>{{textInfo.beian}}</p>
        </div>
      </div>

      <div class="box debt-container" style='background: url(https://toufang-images.sybaishan.top/images/quanyi/qyhf/debt.png) 0% 0% / 380px 100% no-repeat;'>
          <div style=" font-size: 14px;color: #2851b5;font-weight: 700;">• 大额补贴 | 绝对超值 •</div>
          <div class="lan-sty">
            <div class="lan-sty-tips">支付后领取12个小时之内到账</div>
            <div class="lan-sty-cont">
              <div class="lan-sty-left">
                <div class="left-title">20元话费</div>
                <div class="left-tips">请查看到账短信通知</div>
              </div>
              <div class="lan-sty-rig">
                <div>等待领取</div>
              </div>
            </div>
          </div>
          <div class="hong-sty" v-for="n in counts" :key="n">
            <div class="lan-sty-tips">每月领取20元话费</div>
            <div class="lan-sty-cont">
              <div class="lan-sty-left">
                <div class="left-title">20元话费</div>
                <div class="left-tips">每月需要手动领取超划算</div>
              </div>
              <div class="lan-sty-rig">
                <div>等待领取</div>
              </div>
            </div>
          </div>
      </div>



      <div class="box" style="display: none;">
        <img src="https://ntyz.oss-cn-shanghai.aliyuncs.com/image/zdd/143e31c538d5efdf14ad3aa6f21bdea6.jpg" style="width: 100%;" alt="">
        <!-- <div class="lm t-c">话费优惠券包说明</div>
        <div class="content mt15" v-html="detail.detail"></div> -->

        <div class="font-color-gray t-c " style="position: absolute;/* bottom: 0; */width: 90%;top: 2080px;background-color: #fff;margin: 0 auto;left: 10vw;">
          <p>{{textInfo.company_name}}</p>
          <p>客服电话：4006178158</p>
          <p>网站备案号/许可证号:苏ICP备2021030249号-1</p>
        </div>
      </div>
    </div>

    <!--<div class="pay_status" v-if="create_status==1">
      <div class="success" v-if="paysuccess==1">
        <div><van-icon name="checked" size="120" color="#07c160"/></div>
        <div class="h4">支付成功</div>
        <div class="mt10 count-down">
          <van-count-down :time="paytime" @finish="finishPay">
            <template #default="timeData">
              <span class="block">{{ timeData.seconds }}</span>
              <span>S 后跳转</span>
            </template>
          </van-count-down>
        </div>
      </div>
      <div class="fail" v-if="paysuccess==0">
        <div class="h4">请在新打开的支付平台页进行支付</div>
        <div class="mt40"><van-button type="primary" color="#e9333f" block @click="onSubmitPay(1)">我已支付，立即升级</van-button></div>
        <div class="mt20"><van-button type="primary" color="#e9333f" block @click="onSubmitPay(0)">支付失败，重新支付</van-button></div>
      </div>
    </div>-->

    <van-popup v-if="contentHtmlModal" v-model="contentHtmlModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="contentPop">
      <div class="html" v-html="contentHtml"></div>
      <div class="close"><van-button type="primary" round color="#2d8cf0" style="width:60%" @click="()=>{contentHtmlModal = false}">确定</van-button></div>
    </van-popup>
  </div>
</template>
<script>
  import moment from 'moment'
  import {isWeixin,keepToInt,getordernum} from '@/utils/utils'
  import {initCloud} from '@wxcloud/cloud-sdk'
  import {GetPayConfig, GetUserPrivate,CreateOrder, GetOrderStatus} from './services/services'
  export default {
    name:'pay100_1',
    components: {},
    data () {
      return {
        show:false,
        textInfo:{},
        contentHtml:'',
        time:1000*60*10,
        counts:9,
        detail:{},
        moneyList:[],
        payList:[],
        money:{},
        json:{
          pay_way:0,
          order_sn:'',
          phone:'',
          url:location.href
        },
        create_status:0,
        paysuccess:0,
        paytime:3 * 1000,
        isWeixin:false,
        count:0,
        timer:null,
        isread:true,
        contentHtmlModal:false,
        type:'',
        is_open_refund:false,
        kefu_url:''
      }
    },
    created(){
      const {phone} = this.$route.query
      this.json={
        ...this.json,
        ...this.$route.query,
        phone:(phone==undefined?'':atob(phone))
      }
      this.isWeixin = isWeixin()?1:0
      // this.isWeixin = 1
      if(this.isWeixin){
        if(this.json.openid == ''){
          this.getOpenId()
        }
        this.json.pay_way = 14
      }
      this.type = this.json.type==undefined ? undefined:this.json.type
    },
    filters:{
      keepToInt
    },
    computed:{
    },
    watch:{
      'json.phone':{
        handler:function(value){
          if(value.length == 11){
            if(!/^1[3456789]\d{9}$/.test(value)) {
              this.$toast('请输入正确的手机号码')
              this.$refs.phone.focus()
            }else{
              this.$refs.phone.blur()
            }
          }
        },
        deep:true
      }
    },
    mounted(){
      if(sessionStorage.getItem('order_info')){
        let order_info = JSON.parse(sessionStorage.getItem('order_info'))
        this.json={
          ...this.json,
          ...order_info
        }

        this.findOrderStatus(this.json.order_sn)
      }
      if(this.json.order_sn){
        this.findOrderStatus(this.json.order_sn)
      }
      this.findpayConfig()
      this.finduserprivate()
    },
    methods:{
      moment,
      initCloud,
      getOpenId(){
        const redirect_url = location.href
        window.location.href = 'https://pay2.senhuo.cn/pay/WechatOpenId.php?redirect_url=' + encodeURIComponent(redirect_url)
      },
      service(){
        location.href = this.kefu_url
      },
      findpayConfig(){
        GetPayConfig({type:this.type}).then(result => {
          if(result.data.code==1){
            const {goodsArray,payConfig} = result.data.data
            this.detail = result.data.data
            this.moneyList=goodsArray
            this.payList=payConfig
            this.json.pay_way=this.payList[0].payWay
            let ValidList=goodsArray.filter(item=>item.status == 1)
            if(ValidList.length){
              this.money = ValidList[0]
            }
            this.is_open_refund = result.data.data.is_open_refund

            const {app_download_url,kefu_url} = this.detail
            this.kefu_url = kefu_url
            let data = {app_download_url,kefu_url}
            sessionStorage.setItem('payConfig',JSON.stringify(data))
          }else{
            this.$toast.fail(result.data.msg)
          }
        })

      },
      finduserprivate(){
        GetUserPrivate().then(result => {
          if(result.data.code==1){
            this.textInfo = result.data.data
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      onChangeMoney(record){
        if(record.status){
          this.money = record
        }
      },
      onsubmit(){
        if(!this.isread){
          this.$toast('请阅读隐私协议与用户协议')
          return false
        }
        if(this.json.phone==''){
          this.$toast('请输入充值手机号')
          this.$refs.phone.focus()
          return false
        }else if(!/^1[3456789]\d{9}$/.test(this.json.phone)){
          this.$toast('请输入正确的手机号码')
          this.$refs.phone.focus()
          return false
        }
        this.$toast.loading({
          message: '正在提交订单...',
          forbidClick: true,
        })
        let pay=this.payList.find(item=>item.payWay == this.json.pay_way)
        const {pwyAccount} = pay
        let order_sn = this.json.order_sn==''?getordernum(6):this.json.order_sn
        let json={
          ...this.json,
          id:this.money.id,
          account:pwyAccount,
          order_sn:order_sn,
          type:this.type
        }
        this.getCreateOrder(json)
      },
      getCreateOrder(res){
        this.findOrderStatus(res.order_sn)
        CreateOrder({...res,openid:(this.isWeixin?this.json.openid:undefined),pay_tag:((this.isWeixin || res.pay_way==14)?'wechat':'alipay')}).then(result => {
          if(result.data.code==1){
            sessionStorage.setItem('order_info',JSON.stringify(res))
            const {data} = result.data
            let pay_way = res.pay_way

            if(pay_way==14){
              location.href=data
              // this.payWeChat(data)
            }else if(pay_way==16){
              this.Alipay(data)
            }


          }else{
            this.$toast.fail(result.data.msg)
          }
        })
      },
      async payWeChat(orderId){
        let c1 = new this.initCloud().Cloud({
          // 必填，表示是未登录模式
          identityless: true,
          // 资源方 AppID
          resourceAppid: 'wxdada9bc45300a359',
          // 资源方环境 ID
          resourceEnv: 'cloud1-3gua9rkm875bd364',
        })

        await c1.init()
        const res =  await c1.callFunction({
          name: 'public',
          data: {
            action: 'getUrlScheme',
            path:'pages/index/index', // 想跳转的小程序路径
            orderId:orderId, // 预下单单号
          },
        }).then((res)=>{
          let result = res.result
          if(result.errCode==0){
            location.href = result.openlink
          }
        })
      },
      Alipay(res){
        const div = document.createElement("formdiv")
        div.innerHTML = res
        document.body.appendChild(div)
        document.forms["pay"].setAttribute("target", "_self")
        document.forms["pay"].submit()
        div.remove()
      },
      findOrderStatus(order_sn){
        GetOrderStatus({order_sn:order_sn}).then(result => {
          const {code} = result.data
          if(code==1){
            this.paysuccess = code
            this.count = 0
            clearInterval(this.timer)
            location.href='https://app.qdsjhh.com/h5'
            // this.$router.push({path:'/success'})
          }else{
            if(this.count>=3){

            this.$dialog.confirm({
              title: '确认支付情况',
              confirmButtonText:'已完成支付',
              cancelButtonText:'重新支付',
              closeOnClickOverlay:true
            }).then(() => {
              this.finishPay(order_sn)
            }).catch((res) => {
              if(res == 'overlay'){
                this.clearTime()
              }else{
                this.getCreateOrder(this.json)
              }
            })
          }
            if(this.timer == null){
              this.getTimerStatus(order_sn)
            }

          }
        })
      },
      getTimerStatus(order_sn){
        this.timer = setInterval(() => {
          if(this.count>=5){
            // this.create_status=1
            this.clearTime()
            return false
          }
          this.count=this.count+1
          this.findOrderStatus(order_sn)
        },3*1000)
      },
      finishPay(order_sn){//支付完成
        GetOrderStatus({order_sn:order_sn}).then(result => {
          const {code} = result.data
          if(code==1){
            this.paysuccess = code
            this.clearTime()
            this.$router.push({path:'/success'})
          }else{
            this.$toast.fail(result.data.msg)
          }
        })
        // location.href = 'https://api.youzuanmy.vip/xd/paysuccess.html?orderNo='+this.json.order_sn
      },
      clearTime(){
        clearInterval(this.timer)
        sessionStorage.removeItem('order_info')
      },
      onSubmitPay(status){
        switch (status) {
          case 1:
            this.finishPay()
            break;
          case 0:
            this.getCreateOrder(this.json)
            break;
        }
      }
    }
  }

</script>
<style lang="less">
  @import "./css/style1.less";
</style>
